
import { defineAsyncComponent, defineComponent } from 'vue'
import router from '@/router'
import { ReportActions } from '@/store/modules/reports/actions'

export default defineComponent({
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue')),
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue'))
  },
  data () {
    return {
      showFilterModal: false,
      tableFields: [
        { id: 'adminUsername', name: 'Admin', width: '25%', sortable: false, currentSort: false, sort: null, sortName: 'driverFullName' },
        { id: 'totalCashSettlements', name: 'Total Cash Settlements', width: '25%', sortable: false, currentSort: false, sort: null, sortName: 'financialLimitExceed' },
        { id: 'settledOrders', name: 'Settled Orders', width: '25%', sortable: false, currentSort: false, sort: null, sortName: 'totalBalance' },
        { id: 'totalCashSettlementsAmount', name: 'Total Cash Settlements Amount', width: '25%', sortable: false, currentSort: false, sort: null, sortName: 'unpaidOrders' }
      ],
      filters: [
        { name: this.$t('filters.createdAt'), model: 'locationDate', type: 'date', calendarType: 'range' }
      ],
      selectedReport: this.reportType,
      exportLoading: false,
      exportData: [] as any,
      exportFields: [
        { label: 'Admin', field: 'adminUsername' },
        { label: 'Total Cash Settlements', field: 'totalCashSettlements' },
        { label: 'Settled Orders', field: 'settledOrders' },
        { label: 'Total Cash Settlements Amount', field: 'totalCashSettlementsAmount' },
        { label: 'Cash Settlement Id', field: 'cashSettlementId' },
        { label: 'Settlement No (Smart ID)', field: 'settlementNo' },
        { label: 'Date', field: 'date' },
        { label: 'Amount', field: 'amount' },
        { label: 'Status', field: 'status' },
        { label: 'ERP Sync Status', field: 'erpNextSyncStatus' },
        { label: 'Driver', field: 'driverUsername' }
      ],
      searchValue: null as any
    }
  },
  methods: {
    menuEvent (e: any): void {
      console.log(e)
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    },
    onExpand (eventName: string): void {
      console.log(eventName)
    },
    exportTableToExcel (event: any) {
      event.stopPropagation()
      this.exportLoading = true
      this.$store.dispatch(`reports/${[ReportActions.FETCH_REPORTS]}`, { reportType: this.reportType, export: true, pageNumber: 1, pageSize: this.pagination?.totalItems || 100, ...this.$route.query })
        .then((res: any) => {
          const button = document.getElementById('excel-export')
          res.data.forEach((item: any) => {
            const obj = {
              adminUsername: item.adminUsername,
              totalCashSettlements: item.totalCashSettlements,
              settledOrders: item.settledOrders,
              totalCashSettlementsAmount: item.totalCashSettlementsAmount + ' IQD',
              cashSettlementId: '',
              settlementNo: '',
              date: '',
              adminActionDate: '',
              amount: '',
              status: '',
              driverUsername: '',
              erpNextSyncStatus: ''
            }
            this.exportData.push({ ...obj })
            item.cashSettlementItems.map((child: any) => {
              obj.adminUsername = ''
              obj.totalCashSettlements = ''
              obj.settledOrders = ''
              obj.totalCashSettlementsAmount = ''
              obj.cashSettlementId = child.cashSettlementId
              obj.settlementNo = child.settlementNo
              obj.date = child.date
              obj.adminActionDate = child.adminActionDate
              obj.amount = child.amount ? child.amount + ' IQD' : ''
              obj.status = child.status
              obj.driverUsername = child.driverUsername
              obj.erpNextSyncStatus = child.erpNextSyncStatus
              this.exportData.push({ ...obj })
            })
            obj.adminUsername = ''
            obj.totalCashSettlements = ''
            obj.settledOrders = ''
            obj.totalCashSettlementsAmount = ''
            obj.cashSettlementId = ''
            obj.settlementNo = ''
            obj.date = ''
            obj.adminActionDate = ''
            obj.amount = ''
            obj.status = ''
            obj.driverUsername = ''
            obj.erpNextSyncStatus = ''
            this.exportData.push({ ...obj })
          })
          if (button) {
            setTimeout(() => {
              button.click()
              this.exportLoading = false
            }, 200)
          }
        })
        .finall(() => {
          this.exportLoading = false
        })
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    },
    searchValue () {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, adminUsername: this.searchValue.length ? this.searchValue : undefined } })
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    reports () {
      return this.$store.getters['reports/getAdminWalletReports'].map((data: any) => {
        return {
          ...data,
          totalCashSettlementsAmount: data.totalCashSettlementsAmount + ' IQD',
          cashSettlementItems: data.cashSettlementItems.map((c: any) => {
            return {
              ...c,
              amount: c.amount ? c.amount + ' IQD' : ''
            }
          })
        }
      })
    },
    exportReports () {
      return this.$store.getters['reports/getAdminWalletReportsExport'].map((data: any) => {
        return {
          ...data,
          totalCashSettlementsAmount: data.totalCashSettlementsAmount + ' IQD',
          cashSettlementItems: data.cashSettlementItems.map((c: any) => {
            return {
              ...c,
              amount: c.amount + ' IQD'
            }
          })
        }
      })
    },
    pagination () {
      return this.$store.getters['reports/getAdminWalletPagination']
    },
    loading () {
      return this.$store.getters['reports/getLoading']
    }
  }
})
